<script setup>
    const faqs = [
        {
            question: 'Как создать электронный дневник садовода-огородника?',
            answer: 'Зарегистрируйтесь на сайте Умный Огород. Придумайте название огорода и добавьте ваш первый сезон. Добавьте растения, которые планируете выращивать. Они автоматически соберутся в группы. Если повернуть телефон в горизонтальное положение вы увидите дополнительные колонки для указания количества, важных дат. На компьютере все поля доступны сразу.'
        },
        {
            question: 'Можно скачать и распечатать электронный дневник садовода?',
            answer: 'Вы можете распечатать свои дневник в формате А4 или А5. Для этого на вкладке "Журнал" нажмите на "Троеточие" справа и "Распечатать" в выпадающем меню. Появится всплывающее окно для выбора варианта печати. Тут нужно оставить самые необходимые колонки, выбрать обложку и формат печати.'
        },
        {
            question: 'Как скачать образец дневника садовода?',
            answer: 'Вы можете <a href="/help/download" target="_blank">скачать образец дневника садовода</a> для печати и сборки своими руками. Все шаблоны доступны бесплатно.',
        },
        {
            question: 'Сколько стоит дневник садовода на сайте Умный Огород?',
            answer: 'Дневник бесплатный.',
        },
        {
            question: 'Зачем вести дневник садовода?',
            answer: 'Ведение записей позволяет избежать повторения ошибок. С помощью дневника вы узнаете больше о вашем огороде. C каждой записью или загруженной фотографией вы лучше понимаете, как выращивать именно в вашей местности и климатических условиях.',
        },
        {
            question: 'Как вести дневник садовода-огородника?',
            answer: 'Ведите учет количества сортов и растения, отмечайте важные даты, например дата посева, дата высадки в грунт. Добавляйте фото и текстовые заметки. Делитесь опытом и своими находками в блоге.',
        },
        {
            question: 'Что такое сообщество Умный Огород?',
            answer: 'Умный Огород - это место общения практиков, садоводов любителей и профессионалов. Тут мы делимся полезной информацией и достижениями.',
        },
        {
            question: 'Как связаться с автором сайта Умный Огород?',
            answer: 'Для связи с автором вступите в группу <a href="https://t.me/+ycnelp0LSOYyYjNi" target="_blank">Умный Огород в telegram</a>.',
        }
    ];

    useJsonld([{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Умный огород",
        "alternateName": "Uogorod",
        "url": "https://uogorod.ru/",
        "logo": "https://uogorod.ru/pwa-144x144.png",
        "description": "Место где вы можете общаться, обмениваться информацией, делиться своими достижениями, а также наблюдать, как за своим огородом, так и грядками других пользователей.",
        "foundingDate": "2016",
        "email": "mail@uogorod.ru",
        "founders": [
            {
                "@type": "Person",
                "name": "Александр Устюхин",
              	"image": "https://uogorod.ru/files/userpic/5.webp",
              	"url": "https://uogorod.ru/profiles/5",
              	"jobTitle": "Основатель сообщества Умный Огород"
            }
        ]
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqs.map(({ question, answer}) => {
            return {
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": answer
                }
            }
        })
    }]);

    definePageMeta({
        middleware: function(to, from) {
            const client = useUser();

            if (client.value?.client_id) return navigateTo('/feed', { replace: true });
        },
    });
    useSeoMeta({
		title: 'Умный Огород - электронный дневник садовода-огородника',
        titleTemplate: '',
		description: 'Умный Огород - это бесплатный и удобный сервис планирования сада или огорода. Отмечайте важные даты, загружайте фото растений и текстовые заметки.',
	})
</script>

<template>

        <article class="p-6 rounded-xl bg-white grid gap-16">
            <div class="text-center mb-8">
                <h1 class="mb-0 xl:text-5xl leading-none"><span class="text-green-600">Электронный</span> дневник cадовода <br>Умный Огород</h1>
                <p class="mb-4 text-xl font-bold">Понятный. Бесплатный. Удобный.</p>
                <p class="m-0 mb-4 text-xl">
                    Умный Огород — это ваш надежный помощник в садоводстве, который объединяет все необходимые инструменты для наблюдения за растениями в одном месте. Дневник доступен  в электронном и бумажном виде. 
                </p>
                <p>
                    <CommonButton class="bg-green-600 hover:bg-emerald-600 text-white text-xl py-3 px-6" to="/auth/signup">Создать дневник</CommonButton>
                </p>
                <picture>
                    <source media="(min-width: 640px)" srcset="@/assets/land-preview.webp" />
                    <img class="h-auto bg-white" src="@/assets/land-preview-sm.webp" alt="Электронный дневник садовода-огородника" />
                </picture>
            </div>
            
            <div><h2 class="m-0 text-4xl text-center">Особенности дневника</h2>
                <p class="m-0 text-xl font-bold text-center">
                Почему Умный Огород лучший дневник для садоводов
                </p>
            </div>
            <div class="m-0 grid gap-8 grid-cols-1 md:grid-cols-3 xl:grid-cols-3">
                    <div class="text-center m-0">
                        <img class="bg-white w-48" src="~/assets/misc/Frame-325.webp" alt="Простота и удобство" />
                        <h3 class="my-0 text-2xl">Простота и удобство</h3>
                        <p class="my-0">Легко добавляйте растения, планируйте задачи, пишите заметки</p>
                    </div>
                    <div class="text-center m-0">
                        <img class="bg-white w-48" src="~/assets/misc/Frame-327.webp" alt="Доступность" />
                        <h3 class="my-0 text-2xl">Доступность</h3>
                        <p class="my-0">Используйте дневник на смартфоне, компьютере или распечатайте в формате А4, А5</p>
                    </div>
                    <div class="text-center m-0">
                        <img class="bg-white w-48" src="~/assets/misc/Frame-328.webp" alt="Безлимит" />
                        <h3 class="my-0 text-2xl">Безлимит</h3>
                        <p class="my-0">Создавайте огороды, добавляйте сезоны, растения, заметки - без ограничений, бесплатно</p>
                    </div>
            </div>
            <div class="bg-slate-50 p-8 rounded-xl">
                <div class="flex items-center justify-between">
                    <div class="troboto">
                        <span class="text-4xl font-bold">Привет!<br>это Александр Устюхин</span>
                        <p class="mt-0">основатель сообщества Умный Огород.</p>
                    </div>
                    <img class="hidden sm:inline w-auto rounded-full aspect-square flex-shrink-0" src="~/assets/misc/alexander_ustyukhin.webp" alt="Александр Устюхин" width="96" height="96" />
                </div>
                <p>Все началось с идеи построить удобный и полезный сайт для думающих садоводов. Это место для садоводов желающих из года в год получать гарантированный высокий результат. Мы делаем выбор в пользу лучших сортов семян в сочетании с лучшими агротехниками. Присоединяйся к нам! Начните с создания своего дневника садовода-огородника.</p>
                <div class="grid gap-4 items-center justify-center sm:flex">
                    <CommonButton class="bg-green-600 hover:bg-emerald-600 text-white text-xl py-3 px-6" to="/auth/signup">Создать дневник</CommonButton>
                    <CommonButton class="bg-sky-500 hover:bg-sky-600 text-white text-xl py-3 px-6" to="https://t.me/uogorod_ru">
                        <svg-icon class="mr-2" name="telegram-white" width="28" height="28"/>
                        Давайте общаться
                    </CommonButton>
                </div>
            </div>
            
            <div>
                <h2 class="m-0 text-4xl text-center">Посмотрите примеры дневников</h2>
            </div>
            <div class="grid gap-8 grid-cols-1 md:grid-cols-5 xl:grid-cols-5 content-center">
                <a class="text-center" href="https://uogorod.ru/profiles/5/gardens/2/2024" target="_blank"><img class="sm:inline w-32 rounded-full aspect-square flex-shrink-0" src="@/assets/misc/alexander_ustyukhin.webp" alt="@Alexander_Ustyukhin">@Alexander_Ustyukhin</a>
                <a class="text-center" href="https://uogorod.ru/profiles/1533/gardens/1495/2025" target="_blank"><img class="sm:inline w-32 rounded-full aspect-square flex-shrink-0" src="@/assets/misc/1533.webp" alt="@Марина">@Марина</a>
                <a class="text-center" href="https://uogorod.ru/profiles/700/gardens/699/2024" target="_blank"><img class="sm:inline w-32 rounded-full aspect-square flex-shrink-0" src="@/assets/misc/700.webp" alt="@Styura">@Styura</a>
                <a class="text-center" href="https://uogorod.ru/profiles/1777/gardens/1648/2024" target="_blank"><img class="sm:inline w-32 rounded-full aspect-square flex-shrink-0" src="@/assets/misc/1777.webp" alt="@Karina">@Karina</a>
                <a class="text-center" href="https://uogorod.ru/profiles/1483/gardens/1722/2024" target="_blank"><img class="sm:inline w-32 rounded-full aspect-square flex-shrink-0" src="@/assets/misc/1483.webp" alt="@Алексей">@Алексей</a>
            </div>

            
            <div>
                <h2 class="m-0 text-4xl text-center">Создайте дневник в пару кликов</h2>
            </div>
            <div class="m-0 grid gap-8 grid-cols-1 md:grid-cols-3 xl:grid-cols-3">
                    <div class="text-center m-0">
                        <img class="bg-white w-24" src="~/assets/misc/step1.webp" alt="Зарегистрируйтесь" />
                        <h3 class="my-0 text-2xl">Зарегистрируйтесь</h3>
                        <div class="my-0">Укажите имя, почту, пароль<br>и подтвердите почту</div>
                        <img class="bg-white w-72" src="~/assets/misc/podtverdit-registratsiju.webp" alt="Зарегистрируйтесь" />
                    </div>
                    <div class="text-center m-0">
                        <img class="bg-white w-24" src="~/assets/misc/step2.webp" alt="Создайте огород" />
                        <h3 class="my-0 text-2xl">Создайте огород</h3>
                        <div class="my-0">Нажмите "создать огород"<br>Выберите название</div>
                        <img class="bg-white w-72" src="~/assets/misc/sozdat-ogorod.webp" alt="Создайте огород" />
                    </div>
                    <div class="text-center m-0">
                        <img class="bg-white w-24" src="~/assets/misc/step3.webp" alt="Добавьте растение в огород" />
                        <h3 class="my-0 text-2xl">Добавьте растение</h3>
                        <div class="my-0">Занесите все растния сезона<br>Позже можно добавить другие сезоны</div>
                        <img class="bg-white w-72" src="~/assets/misc/dobavit-rastenie.webp" alt="Добавьте растение в огород" />
                    </div>
            </div>
            <CommonButton class="bg-green-600 hover:bg-emerald-600 text-white text-xl py-3 px-6 justify-self-center" to="/auth/signup">Зарегистрироваться</CommonButton>
            <section class="grid gap-16">
                <article class="xl:flex gap-16 items-start mb-8">
                    <div>
                        <h2 class="m-0 text-3xl">Все ваши растения собраны в одном месте</h2>
                        <p class="mb-0 text-xl font-bold">
                        Удобный инструмент для планирования посадок
                        </p>
                        <p class="m-0 text-xl">
                            Добавьте несколько растений, а дневник сам соберет их в группы и посчитает число сортов. 
                        </p>
                        <p class="flex justify-center w-full">
                            <CommonButton class="bg-green-600 hover:bg-emerald-600 text-white text-xl py-3 px-6" to="/auth/signup">Начать</CommonButton>
                        </p>
                    </div>
                    <video class="flex-shrink-0 xl:w-1/2 w-full h-auto" autoplay="" loop="" muted="" src="@/assets/steps/add-seeds480.mp4" playsinline="" pip="false" preload="auto"></video>
                </article>

                <article class="xl:flex gap-16 items-start xl:flex-row-reverse mb-8"> <!-- Такой же блока как и предыдущий, разворачиваем его с помощью класса xl:flex-row-reverse -->
                    <div>
                        <h2 class="m-0 text-3xl">Фото и текстовые заметки</h2>
                        <p class="mb-0 text-xl font-bold">
                            Создайте историю растения от посева до сбора урожая!
                        </p>
                        <p class="m-0 text-xl">
                            Отмечайте важные даты, добавляйте заметки. C каждой записью вы лучше понимаете, как выращивать именно в вашей местности.
                        </p>
                        <p class="flex justify-center w-full">
                            <CommonButton class="bg-green-600 hover:bg-emerald-600 text-white text-xl py-3 px-6" to="/auth/signup">Начать</CommonButton>
                        </p>
                    </div>
                    <img class="flex-shrink-0 xl:w-1/2 h-auto" src="~/assets/land-002.webp" alt="Фото и текстовые заметки" />
                </article>
            </section>

            <h2 class="m-0 text-4xl text-center">Партнеры проекта</h2>

            <div class="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-4">
                    <!--
                        Лучшее кол-во карточек это то которое делится на 3 и делится на 2
                        например 6 12...
                    -->
                    <a class="w-[190px]" href="http://www.semco.ru" rel ="nofollow" target="_blank">
                        <img class="bg-white" src="~/assets/partners/semco-logo.webp" alt="Лого Агрофирма Семко" />
                    </a>
                    <a class="w-[190px]" href="https://online.semenasad.ru" rel ="nofollow" target="_blank" alt="Online semena">
                        <img class="bg-white" src="~/assets/partners/poisk-logo.webp" alt="Лого Агрохолдинг Поиск" />
                    </a>
                    <a class="w-[190px]" href="http://www.freshtomat.ru" rel ="nofollow" target="_blank">
                        <img class="bg-white" src="~/assets/partners/ft-logo.webp" alt="Лого Freshtomat" />
                    </a>
                    <a class="w-[190px]" href="https://agrotehnika.org/" target="_blank">
                        <img class="bg-white" src="~/assets/partners/lt-logo.webp" alt="Лого Любопытный Агротехнолог" />
                    </a>
            </div>

            <div class="relative w-full bg-white pb-4 sm:mx-auto">
            <div class="mx-auto">
                <div class="flex flex-col items-center">
                    <h2 class="mt-5 text-center text-4xl font-bold tracking-tight">Ответы на частые вопросы</h2>
                </div>
                <div class="mx-auto grid max-w-4xl divide-y divide-neutral-200">
                    <div class="py-5" v-for="{question, answer} in faqs">
                        <details class="group">
                            <summary class="flex cursor-pointer list-none items-center justify-between text-2xl font-bold">
                                <span>{{question}}</span>
                                <span class="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                            </summary>
                            <p class="group-open:animate-fadeIn mt-3 text-neutral-600" v-html="answer"></p>
                        </details>
                    </div>
                </div>
            </div>
        </div>
    </article>

    <PostGrid />
</template>